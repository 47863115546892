import $ from 'jquery';

import {
	subscribe,
	// unsubscribe,
} from 'Util/pubsub';

var $window;
var $heroSticyNav;
var $heroStickyNavTrigger;

var stickyHeroNav = {
	init: function () {
		$window = $(window);
		$heroSticyNav = $('.js-hero__sticky-subnav');
		$heroStickyNavTrigger = $(".js-hero__sticky-subnav-trigger");
		stickyHeroNav._initEvent();
	},

	_initEvent: function () {
		subscribe('/window/scroll', stickyHeroNav._setStickiness);
	},

	_setStickiness: function () {
		//var notificationHeight;
		var heroStickyNavOffSet = $heroStickyNavTrigger.offset().top;
		var scrollTop;

		var isSticky = $heroSticyNav.hasClass("is-sticky");
		var shouldBeSticky;
		let mql = window.matchMedia('(max-width: 1023px)');

		scrollTop = $window.scrollTop();

		shouldBeSticky = scrollTop >= heroStickyNavOffSet;

		if (mql.matches) {
			if (isSticky !== shouldBeSticky) {
				if (shouldBeSticky) {
					$heroSticyNav.addClass("is-sticky");
				} else {
					$heroSticyNav.removeClass("is-sticky");
				}
			}
		}
	},
};

export { stickyHeroNav };