import { publish } from 'Util/pubsub';

import $ from 'jquery';

const selectors = Object.freeze({
	autoexpand: '.js-autoexpand',
});

const pubsubEvents = Object.freeze({
	open: '/expandcollapse/open',
	close: '/expandcollapse/close',
});

export function init() {
	/** @type {NodeListOf<HTMLElement>} */
	const $autoexpands = document.querySelectorAll(selectors.autoexpand);
	$autoexpands.forEach(_autoexpand);
}

/**
 * Looks at an element's CSS to determine if it is expanded or not.
 * Currently relies on the assumption that only expanded elements are
 * visible, and that visibility is determined by its CSS display rule.
 *
 * @param  {HTMLElement} $element
 *
 * @return {boolean}
 */
function isExpanded($element) {
	const styles = window.getComputedStyle($element);

	return styles.display !== 'none';
}

/**
 * Check if the element has its state set explicitly.
 * Currently assumes the "aria-expanded" attribute is being used for this.
 *
 * @param  {HTMLElement} $element
 *
 * @return {boolean}
 */
function isStateExplicit($element) {
	const ariaExpanded = $element.getAttribute('aria-expanded');

	return ariaExpanded !== null;
}

/**
 * Determine if $element is open or closed based on CSS,
 * then apply correct attribute state for expand-collapse.
 *
 * @param  {HTMLElement} $element
 */
function _autoexpand($element) {
	// Expand/Collapse expects the element in a jQuery wrapper
	const $jqueryElement = $($element);

	if (isStateExplicit($element) === false) {
		if (isExpanded($element)) {
			publish(pubsubEvents.open, $jqueryElement);
		} else {
			publish(pubsubEvents.close, $jqueryElement);
		}
	}
}
