import * as keys from 'Util/keybinding';
import { listen } from 'Util/core';

const tooltip = (function () {
	const selectors = Object.freeze({
		tooltip: '.js-tooltip',
		body: '.js-tooltip__body',
		close: '.js-tooltip__close',
	});

	const classes = Object.freeze({
		hidden: 'c-tooltip--hidden',
		left: 'c-tooltip--left',
		right: 'c-tooltip--right',
	});

	const wiggleRoom = 18; // px, should be kept in sync with CSS

	let initialised = false;
	let closeButtonClicked = false;

	const module = {
		init: function () {
			if (initialised === false) {
				module._setTooltipPositions();
				module._initEvents();
				module._initKeybinding();
			}

			initialised = true;
		},

		_initEvents: function () {
			const $tooltips = document.querySelectorAll(selectors.tooltip);

			window.addEventListener('resize', module._setTooltipPositions);

			$tooltips.forEach(($tooltip) => {
				// When the tooltip either has the cursor leave it, or loses keyboard focus,
				// remove the class that can keep it hidden if the user pressed the escape key.
				$tooltip.addEventListener('mouseleave', module._removeHiddenClassEvent($tooltip));
				$tooltip.addEventListener('focusout', module._processTooltipFocusOut);
			});

			listen(selectors.close, 'click', module._processCloseButtonClick);
		},

		_initKeybinding: function () {
			keys.bind('escape', module._hideOpenTooltips);
		},

		_setTooltipPositions: function () {
			const $tooltips = document.querySelectorAll(selectors.tooltip);

			$tooltips.forEach(module._setTooltipPosition);
		},

		/**
		 * Momentarily display the tooltip in its default position, and check if it
		 * disappears of either the left or right edge of the window. If it does,
		 * then apply a class to adjust its position accordingly.
		 *
		 * @param {HTMLElement} $tooltip
		 */
		_setTooltipPosition: function ($tooltip) {
			const windowWidth = window.innerWidth;

			const $body = $tooltip.querySelector(selectors.body);
			const initialDisplay = $body.style.display;

			if ($tooltip.classList.contains(classes.left)) {
				$tooltip.classList.remove(classes.left);
			}
			if ($tooltip.classList.contains(classes.right)) {
				$tooltip.classList.remove(classes.right);
			}
			$body.style.display = 'block';

			const rect = $body.getBoundingClientRect();
			const shouldBeLeft = (rect.left - wiggleRoom) < 0;
			const shouldBeRight = !shouldBeLeft && (rect.right + wiggleRoom) > windowWidth;

			$body.style.display = initialDisplay;

			if (shouldBeLeft) {
				$tooltip.classList.add(classes.left);
			} else if (shouldBeRight) {
				$tooltip.classList.add(classes.right);
			}
		},

		/**
		 * Hide any open tooltips.
		 */
		_hideOpenTooltips: function () {
			const $tooltips = module._getOpenTooltips();

			$tooltips.forEach(module._hideTooltip);
		},

		/**
		 * Hide a given tooltip.
		 *
		 * @param  {HTMLElement} $tooltip
		 */
		_hideTooltip: function ($tooltip) {
			$tooltip.classList.add(classes.hidden);
		},

		_removeHiddenClassEvent: function ($tooltip) {
			return (e) => {
				if (document.activeElement?.closest(selectors.tooltip) !== $tooltip) {
					module._removeHiddenClass($tooltip);
				}
			};
		},

		_removeHiddenClass: function ($tooltip) {
			$tooltip.classList.remove(classes.hidden);
		},

		_processTooltipFocusOut: function (e) {
			// Make asynchronous so new activeElement can be detected
			window.setTimeout(() => {
				let $oldFocus = e.target;
				let $newFocus = document.activeElement;

				let $oldTooltip = module._getClosestTooltip($oldFocus);
				let $newTooltip = module._getClosestTooltip($newFocus);

				if (closeButtonClicked) {
					// If the close button was clicked,
					// the focus will have left and gone to the <body>,
					// but the hidden class should still remain
					closeButtonClicked = false;
				} else if ($oldTooltip !== $newTooltip) {
					module._removeHiddenClass($oldTooltip);
				}
			}, 0);
		},

		_getOpenTooltips: function () {
			const $openBodies = module._getOpenTooltipBodies();

			const $tooltips = [];
			$openBodies.forEach($el => {
				const $tooltip = module._getClosestTooltip($el);

				if ($tooltip) {
					$tooltips.push($tooltip);
				} else {
					console.error('Could not find tooltip of open body:', $el);
				}
			});

			return $tooltips;
		},

		_getOpenTooltipBodies: function () {
			/** @type {NodeListOf<HTMLElement>} */
			const $bodies = document.querySelectorAll(selectors.body);
			const $openBodies = Array.from($bodies).filter($el => {
				// offsetParent returns null if the element or an ancestor has display: none; set,
				// or if it has position: fixed, or if it is <body> or <html>
				// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
				return $el.offsetParent !== null;
			});

			return $openBodies;
		},

		_getClosestTooltip: function ($el) {
			let $tooltip = $el;
			while ($tooltip && $tooltip.matches(selectors.tooltip) === false) {
				$tooltip = $tooltip.parentElement;
			}

			return $tooltip;
		},

		/**
		 * Close the closest parent tooltip.
		 *
		 * @param  {MouseEvent} e
		 */
		_processCloseButtonClick: function (e) {
			/** @type {HTMLElement} */
			const $target = e.target;
			const $tooltip = module._getClosestTooltip($target);

			if ($tooltip) {
				module._hideTooltip($tooltip);

				closeButtonClicked = true;
			}
		},
	};

	return {
		init: module.init
	};
})();

export { tooltip };
